import { Component, OnInit } from '@angular/core';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {RxwebValidators} from "@rxweb/reactive-form-validators";
import {RequesterService} from "../../common/services/requester.service";
import {NzMessageService} from "ng-zorro-antd/message";

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  changePasswordForm: FormGroup;
  isLoading = false;
  constructor(
    private fb: FormBuilder,
    private requesterService: RequesterService,
    private messageService: NzMessageService
  ) { }

  ngOnInit() {
    this.changePasswordForm = this.fb.group({
      password: ['', [Validators.required]],
      newPassword: ['', [Validators.required]],
      confirm_password: ['', [Validators.required, RxwebValidators.compare({fieldName: 'newPassword'})]]
    });
  }

  changePassword() {
    if (!this.changePasswordForm.valid) {
      this.markFormGroupTouched(this.changePasswordForm);

    } else {
      console.log(this.changePasswordForm.value);
      this.requesterService.request('post', 'auth/change-password', { password: this.changePasswordForm.value.newPassword}).subscribe(changed => {
        this.messageService.success('Password changed successfully!');
        this.changePasswordForm.reset();
      }, error => {
        this.messageService.error(error.error.message);
      })
    }

  }

  private markFormGroupTouched(formGroup: FormGroup) {
    (Object as any).values(formGroup.controls).forEach(control => {
      control.markAsTouched();
      control.markAsDirty();
      control.updateValueAndValidity();

      if (control.controls) {
        this.markFormGroupTouched(control);
      }
    });
  }

}
