import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { SocketService } from './common/services/socket.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  url: string;
  constructor(
    private socketService: SocketService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.activatedRoute.queryParams.subscribe(params => {
      if(params.token) {
        const mainUrl = this.url.split('?')[0];
        console.log('AppComponent mainUrl', mainUrl);
        if(mainUrl !== '/auth/verify-registration') {
          this.router.navigate(['auth/quick-login'], { queryParams: {...params, redirectTo: mainUrl} });
        }
      }
    });
    router.events.pipe(first()).subscribe((val:any) => {
      console.log('AppComponent Router Event', val.url);
      const { url } = val;
      this.url = url;
    });
   }
  ngOnInit() {

    this.socketService.connect();
    this.socketService.authentication();

  }
}
